/* eslint-disable react/no-danger */
import React from 'react';

function ContactView(): React.ReactElement {
	return (
		<iframe style={{ height: '100vh', width: '100%' }} title="contact" src="https://wp.courselagoon.com/contact" />
	);
}

export default ContactView;
