/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Course } from '../models/Course';

import { AppThunk } from '../reducers/rootReducer';
import * as courseService from '../services/courseService';
import { load, unload } from './appSlice';

type CourseSliceState = {
	courses: Course[];
};

const initialState: CourseSliceState = {
	courses: [],
};

const courseSlice = createSlice({
	name: 'course',
	initialState,
	reducers: {
		setCourses(state, action: PayloadAction<Course[]>) {
			const { payload: courses } = action;
			return {
				...state,
				courses,
			};
		},
	},
});

export const { setCourses } = courseSlice.actions;

export const getCourses = (): AppThunk => async dispatch => {
	try {
		dispatch(load());
		const courses = await courseService.getCourses();
		dispatch(setCourses(courses));
	} catch (err) {
		// dispatch(getIssuesFailure(err.toString()));
	}
	dispatch(unload());
};

export default courseSlice.reducer;
