/* eslint-disable react/destructuring-assignment */
import React from 'react';
import * as styles from './CourseCard.module.scss';
import ProfilePicture from '../../../assets/images/icons/placeholders/profile-picture.svg';
import BoardBackground from '../../../assets/images/backgrounds/board-background.jpg';

interface Props {
	author: string;
	title: string;
	onClick?: () => void;
}

const CourseCard: React.FC<Props> = (props: Props) => {
	return (
		<div onClick={props.onClick} className={styles.CourseCard}>
			<div className={styles.CourseImage} style={{ backgroundImage: `url(${BoardBackground})` }}>
				<div className={styles.CourseBadge}>โปรโมชั่น!</div>
			</div>
			<div className={styles.CourseDetails}>
				<div className={styles.CouseHeadline}>
					<div className={styles.CourseTitle}>{props.title}</div>
					<div className={styles.CoursePrice}>
						<div className={styles.CoursePrice__New}>฿2,800</div>
						<div className={styles.CoursePrice__Old}>฿3,800</div>
					</div>
				</div>
				<div className={styles.CourseAuthor}>
					<img className="profile-picture" src={ProfilePicture} alt="" />
					{props.author}
				</div>
			</div>
		</div>
	);
};

export default CourseCard;
