/* eslint-disable import/prefer-default-export */
import axios from 'axios';
import { Page } from '../models/Page.d';
import { MAIN_ENDPOINT, PAGE_ENDPOINT } from './urlService';

export async function getPageByPageId(pageId: string | number): Promise<Page> {
	const url = `${MAIN_ENDPOINT}/${PAGE_ENDPOINT.GET_PAGE}/${pageId}`;
	const { data } = await axios.get<Page>(url);
	return data;
}
