import { USERINFO } from '../constants/localStorage';
import store from '../store';
import { setUserInfo } from '../store/authSlice';
import { getCourses } from '../store/courseSlice';
import { getMe, getUsers } from '../store/userSlice';
import { UserInfo } from './authService';

export function initialLoad(): void {
	// eslint-disable-next-line
	const _userInfo = localStorage.getItem(USERINFO);

	if (_userInfo) {
		const userInfo: UserInfo = JSON.parse(_userInfo);
		store.dispatch(setUserInfo(userInfo));
		store.dispatch(getMe(userInfo.token));
	}

	store.dispatch(getCourses());
	store.dispatch(getUsers());
}
