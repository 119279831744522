import React from 'react';
import { useSelector } from 'react-redux';
import { generatePath, Link } from 'react-router-dom';
import CourseCard from '../../components/common/CourseCard/CourseCard';
import GenericContainer from '../../components/common/GenericContainer/GenericContainer';
import { Paths } from '../../router/routes';
import { RootState } from '../../store';

import './Courses.scss';

function Courses(): React.ReactElement {
	const courses = useSelector((state: RootState) => state.course)?.courses;
	const users = useSelector((state: RootState) => state.user)?.users;

	const getPath = (courseId: number): string =>
		generatePath(Paths.Course, {
			courseId,
		});

	return (
		<div id="CoursesView">
			<GenericContainer isNarrow>
				<h1>คอร์สทั้งหมด</h1>

				{courses.map(c => {
					const tutor = users.find(u => u.id === c.author);
					return (
						<Link key={c.id} to={getPath(c.id)} className="link">
							<CourseCard title={c.title.rendered} author={tutor?.name ?? 'CourseLagoon'} />
						</Link>
					);
				})}
			</GenericContainer>
		</div>
	);
}

export default Courses;
