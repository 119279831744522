/* eslint-disable import/prefer-default-export */
import { RouteConfig } from 'react-router-config';

import { Paths } from './routes';

import FallbackView from '../views/Fallback/Fallback';
import CourseView from '../views/Course/Course';
import CoursesView from '../views/Courses/Courses';
import LessonView from '../views/Lesson/LessonView';
import LoginView from '../views/Login/Login';
import ReadingView from '../views/Lesson/Reading/Reading';
import TestView from '../views/Test/Test';
import QuizView from '../views/Quiz/Quiz';
import RegisterView from '../views/Register/Register';
import MyCoursesView from '../views/MyCourses/MyCoursesView';
import ContactView from '../views/ContactView/ContactView';
import HomeapgeView from '../views/HomepageView/HomepageView';
import PurchaseView from '../views/PurchaseView/PurchaseView';

export const routeConfig: RouteConfig[] = [
	// Internal
	getRouteConfig(TestView, Paths.Test),
	// Global
	getRouteConfig(LoginView, Paths.Login),
	getRouteConfig(RegisterView, Paths.Register),
	// Misc
	getRouteConfig(ContactView, Paths.Contact),
	// Contents
	getRouteConfig(CourseView, Paths.Course),
	getRouteConfig(CoursesView, Paths.Courses),
	getRouteConfig(MyCoursesView, Paths.MyCourses),
	getRouteConfig(LessonView, [Paths.Lesson, Paths.Topic]),
	getRouteConfig(QuizView, Paths.Quiz),
	getRouteConfig(ReadingView, Paths.Reading),
	// How tos
	getRouteConfig(PurchaseView, Paths.How2Purchase),
	// Homepaage
	getRouteConfig(HomeapgeView, Paths.Homepage),
	// Fallback
	getRouteConfig(FallbackView, Paths.Fallback),
];

function getRouteConfig(component: RouteConfig['component'], path: Paths | Paths[]): RouteConfig {
	return {
		component,
		path,
		exact: true,
	};
}
