/* eslint-disable import/no-cycle */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Me, User } from '../models/User';

import { AppThunk } from '../reducers/rootReducer';
import * as userService from '../services/userService';

type UserSliceState = {
	me?: Me;
	users: User[];
};

const initialState: UserSliceState = {
	me: undefined,
	users: [],
};

const userSlice = createSlice({
	name: 'user',
	initialState,
	reducers: {
		setMe(state, action: PayloadAction<Me>) {
			// eslint-disable-next-line
			const { payload: _me } = action;
			const me = {
				id: _me.id,
				name: _me.name,
			};
			return {
				...state,
				me,
			};
		},
		setUsers(state, action: PayloadAction<User[]>) {
			const { payload: users } = action;
			return {
				...state,
				users,
			};
		},
	},
});

export const { setMe, setUsers } = userSlice.actions;

export const getUsers = (): AppThunk => async dispatch => {
	try {
		const users = await userService.getUsers();
		dispatch(setUsers(users));
	} catch (err) {
		// dispatch(getIssuesFailure(err.toString()));
	}
};

export const getMe =
	(token: string): AppThunk =>
	async dispatch => {
		try {
			const me = await userService.getMe(token);
			dispatch(setMe(me));
		} catch {
			// TODO
		}
	};

export default userSlice.reducer;
